require('../css/msdropdown/dd.css');
require('bootstrap/dist/css/bootstrap.min.css');

require('jquery/dist/jquery.min.js');
require('./msdropdown/jquery.dd.js');
require('bootstrap/dist/js/bootstrap.min.js');
require('./jquery.hypher.js');
require('./fr.js');

require('../scss/main.scss');

$(document).ready(function(){
    $( document ).on( "click", function( event ) {
        setTimeout(function(){
            $( event.target ).closest( ".navbar-default .navbar-toggle" ).parents('.navbar-default').toggleClass('navbarGlobal');
            $( event.target ).closest( ".navbar-default .navbar-toggle" ).children('p').toggleClass('showTxtMenu');
        }, 100);
    });

    //convert select filter
    $("select.itSelect").msDropdown({roundedBorder:false});
    //createByJson();
    $("#metier").data("dd");

    $('h1, h1 .titre, h2, h3, h4, p, li, h2 a, h3 a, h4 a').hyphenate('fr');

});
// always last to be loaded

if (module.hot) {
    module.hot.accept();
}
